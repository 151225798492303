<template>
  <div>
    <v-navigation-drawer
      app
      right
      temporary        
      width="600"
      :value="settingsOn" 
      @input="close"
    >
      <v-container>
        <v-fade-transition hide-on-leave>
          <div v-if="edit">
            <v-col>
              <span 
                class="grey--text
                  text--darken-2
                  text-subtitle-1"
              >
                Edit
              </span>

              <v-divider class="mt-2 mb-6" />

              <v-text-field
                outlined
                label="Tttle"
                :rules="[rules.title]"
                :value="title"
                @change="setTitle"
              />
              <v-text-field
                outlined
                label="Author"
                :rules="[rules.author]"
                :value="author"
                @change="setAuthor"
              />
              <v-textarea
                outlined
                auto-grow
                label="About"
                :value="description"
                @change="setDescription"
              />

              <v-btn
                text
                @click="submit"
              >
                ok
              </v-btn>

              <v-btn
                text
                @click="edit = false"
              >
                cancel
              </v-btn>
            </v-col>
          </div>
        </v-fade-transition>
        <v-fade-transition hide-on-leave>
          <div v-if="!edit">
            <v-list dense>
              <v-subheader
                class="body-2 mx-2"
              >
                Settings
              </v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="item in optionItems"
                  :key="item.title"
                  link
                >
                  <v-list-item-icon>
                    <v-icon :color="item.color">
                      {{ $data[item.icon] }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-divider class="mx-4" />

            <div v-if="isVideoAdmin">
              <v-list>
                <v-subheader v-text="'Configurações Avançadas'" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title  class="text-h5 font-weight-light">
                      Anonymous
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch 
                      inset
                      color="primary"
                      v-model="isAnonymousInput"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title  class="text-h5 font-weight-light">
                      Public
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch 
                      inset
                      color="primary"
                      v-model="isPublicInput"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title  class="text-h5 font-weight-light">
                      Unlisted
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch 
                      inset
                      color="primary"
                      v-model="isUnlistedInput"
                    />
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              
              <v-divider class="mx-4" />
            </div>
          </div>
        </v-fade-transition>
      </v-container>

      <template v-slot:append v-if="bottomMenu">
        <v-divider />
        <v-row justify="center">
          <v-col
            xl="11"
            lg="11"
          >
            <v-row justify="center">
              <v-btn
                text
                @click="edit = true"
              >
                edit
              </v-btn>

              <v-spacer />

              <v-btn
                text
                color="red darken-4"
              >
                delete
                <v-icon right>
                  {{ mdiDelete }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex'

import {
  mdiDelete,
  mdiBookmarkOutline,
  mdiShareOutline,
  mdiAlertOutline
} from '@mdi/js'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    tags: Array,
    date: String,
    title: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    thumbnail: {
      type: Object,
      required: true
    },
    description: String,
    sender: {
      type: Object,
      default () {
        return {
          name: 'Anonymous',
          bio: 'Mysterious'
        }
      }
    },
    isAnonymous: {
      type: Boolean,
      required: true
    },
    isPublic: {
      type: Boolean,
      required: true
    },
    isUnlisted: {
      type: Boolean,
      required: true
    }
  },

  created () {
    this.isAnonymousInput = this.isAnonymous
    this.isPublicInput = this.isPublic
    this.isUnlistedInput = this.isUnlisted
  },

  data () {
    return {
      mdiDelete,
      mdiBookmarkOutline,
      mdiShareOutline,
      mdiAlertOutline,

      rules: {
        title: v => !!v || 'Qual é o título deste vídeo?',
        author: v => !!v || 'Quem é o autor deste vídeo?'
      },
      titleInput: '',
      authorInput: '',
      descriptionInput: '',
      tagsInput: [],
      isAnonymousInput: null,
      isPublicInput: null,
      isUnlistedInput: null,
      edit: false,
      optionItems: [
        {
          title: 'Save',
          icon: 'mdiBookmarkOutline',
          color: ''
        },
        {
          title: 'Share',
          icon: 'mdiShareOutline',
          color: ''
        },
        {
          title: 'Report',
          icon: 'mdiAlertOutline',
          color: ''
        }
      ]
    }
  },

  watch: {
    isAnonymousInput (v) {
      if (this.settingsOn) {
        const id = this.id
        const data = {
          isAnonymous: v
        }
        this.put({ id, data })
      }
    },
    isPublicInput (v) {
      if (this.settingsOn) {
        const id = this.id
        const data = {
          isPublic: v
        }
        this.put({ id, data })
      }
    },
    isUnlistedInput (v) {
      if (this.settingsOn) {
        const id = this.id
        const data = {
          isUnlisted: v
        }
        this.put({ id, data })
      }
    }
  },

  computed: {
    ...mapGetters({
      settingsOn: 'video/settingsOn',
      profile: 'profile/id'
    }),
    isVideoAdmin () {
      return this.profile === this.sender.profile
    },
    bottomMenu () {
      return !this.edit && this.isVideoAdmin
    }
  },

  methods: {
    ...mapMutations({
      closeSettings: 'video/settingsOn'
    }),
    ...mapActions({
      put: 'video/put'
    }),
    submit () {
      const tags = this.tagsInput.length > 0 ? this.tagsInput : this.tags
      const title = !!this.titleInput ? this.titleInput : this.title
      const author = !!this.authorInput ? this.authorInput : this.author
      const description = !!this.descriptionInput ? this.descriptionInput : this.description
      const data = {tags, title, author, description}
      const id = this.id
      this.put({ id, data })
        .then(() => {
          this.$emit('updated')
        })
    },
    setAuthor (v) {
      this.authorInput = v
    },
    setTitle (v) {
      this.titleInput = v
    },
    setDescription (v) {
      this.descriptionInput = v
    },
    setTags (v) {
      this.tagsInput = v
    },
    close (payload) {
      if (!payload)
        this.closeSettings(false)
    }
  }
}
</script>