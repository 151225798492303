<template>
  <div>
    <Settings
      v-if="!loading"
      :id="id"
      :url="url"
      :tags="tags"
      :title="title"
      :author="author"
      :sender="sender"
      :thumbnail="thumbnail"
      :description="description"
      :isAnonymous="isAnonymous"
      :isPublic="isPublic"
      :isUnlisted="isUnlisted"
      @updated="snackUpdated"
    />

    <Scrollbar :theaterOn="!navOn">
      <div :class="`mb-16 pb-12 ${paddingBar}`">
        <SnackBar
          :snackMsg="snackMsg"
          :snackOn="snackOn"
          @close="snackOn = false"
        />

        <PlaylistDialog
          :model="model"
          :items="items"
          :dialogOn="dialogOn"
          :newVideoList="newVideoList"
          @dialogClose="dialogOn = false"
        />

        <v-btn
          fab
          left
          icon
          bottom
          fixed
          small
          color="primary"
          v-if="navTempOn"
          @click="toggleNavTemp"
        >
          <v-icon color="primary">
           {{ mdiChevronDoubleRight }}
          </v-icon>
        </v-btn>

        <v-fade-transition hide-on-leave>
          <div v-if="loading">
            <v-row
              no-gutters
              justify="center"
            >
              <v-col
                xl="8"
                lg="8"
                class="my-3"
              >
                <v-skeleton-loader
                  :height="`${skeletonHeight}vh`"
                  type="image"
                />
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>

        <div :style="`height: ${playerHeight}px`">
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              :xl="numCols"
              :lg="numCols"
              :class="playerClass"
            >
              <v-fade-transition hide-on-leave>
                <div v-if="!loading">
                  <YouTubePlayer
                    v-if="isYoutube"
                    :videoId="$route.query.id"
                    :url="url"
                    :tags="tags"
                    :title="title"
                    :author="author"
                    :sender="sender"
                    :thumbnail="thumbnail"
                    :description="description"
                    @playerHeight="setPlayerHeight"
                    @theaterModeToggle="theaterModeToggle"
                  />
                  <VideoPlayer
                    v-else
                    :videoId="$route.query.id"
                    :url="url"
                    :tags="tags"
                    :title="title"
                    :author="author"
                    :sender="sender"
                    :thumbnail="thumbnail"
                    :description="description"
                    @playerHeight="setPlayerHeight"
                    @theaterModeToggle="theaterModeToggle"
                  />
                </div>
              </v-fade-transition>
            </v-col>
          </v-row>
        </div>

        <div class="my-4"></div>

        <v-row
          no-gutters
          justify="center"
          v-if="author"
        >
          <v-col
            xl="8"
            lg="8"
            class="pb-0"
          >
            <span
              class="grey--text text--darken-1 text-subtitle-1"
              v-text="`Author: ${author}`"
            ></span>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            xl="8"
            lg="8"
          >
            <v-list-item
              two-line
              class="px-0"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-h5"
                  v-text="title"
                />
                <v-list-item-subtitle
                  class="text-subtitle-1"
                  v-text="dateDistance"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-container class="px-0">
                  <v-btn
                    text
                    large
                    class="mx-4 px-1"
                    color="grey darken-1"
                    disabled
                    @click="dialogOn = true"
                  >
                    save
                    <v-icon right>
                      {{ mdiBookmarkOutline }}
                    </v-icon>
                  </v-btn>

                  <v-btn
                    text
                    large
                    color="grey darken-1"
                    class="px-1"
                    disabled
                  >
                    share
                    <v-icon right>
                      {{ mdiShare }}
                    </v-icon>
                  </v-btn>
                </v-container>
              </v-list-item-action>
            </v-list-item>

            <v-divider />

            <v-list-item
              two-line
              class="my-2 px-0"
            >
              <v-list-item-avatar>
                <v-img
                  :src="sender.avatar"
                  v-if="!isAnonymous && sender.avatar"
                />
                <v-btn
                  v-else
                  icon
                  x-large
                  color="grey darken-1"
                >
                  <v-icon 
                    dark
                    x-large
                  >
                    {{ mdiAccountCircle }}
                  </v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="name" />
                <v-list-item-subtitle v-text="bio" />
              </v-list-item-content>
            </v-list-item>

            <v-row
              no-gutters
              justify="center"
              v-if="description"
            >
              <v-col
                xl="12"
                lg="12"
              >
                <v-btn
                  text
                  @click="expandDescription = !expandDescription"
                  class="px-0"
                >
                  about
                  <v-icon
                    right
                    v-text="$data[expandIcon]"
                  />
                </v-btn>
              </v-col>
            </v-row>

            <v-expand-transition>
              <v-row
                no-gutters
                justify="center"
                v-show="expandDescription"
              >
                <v-col
                  xl="12"
                  lg="12"
                >
                  <div id="description"></div>
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-divider class="my-2" />
          </v-col>
        </v-row>
      </div>
    </Scrollbar>
  </div>
</template>

<script>
import { Scrollbar } from '@components/App'
import { YouTubePlayer, PlaylistDialog, VideoPlayer } from '@components/Video'
import Settings from '@components/Video/Settings'
import { SnackBar } from '@components/App'
import { mapGetters, mapMutations } from 'vuex'
import formatDistance from 'date-fns/formatDistance'
import API from '@api'

import {
  mdiChevronDoubleRight,
  mdiAccountCircle,
  mdiShare,
  mdiBookmarkOutline,
  mdiChevronUp,
  mdiChevronDown
} from '@mdi/js'

export default {
  components: {
    Scrollbar,
    VideoPlayer,
    YouTubePlayer,
    PlaylistDialog,
    Settings,
    SnackBar
  },
  
  async created() {

    try {

      const video = await API().get(`video`, {
        params: {
          id: this.$route.query.id,
          profile: this.profile
        }
      })

      this.SET_LIBRARY_TYPE('video')
      this.SET_LIBRARY_ID(video.id)

      Object.keys(video).forEach(key => {
        this[key] = video[key]
      })

      this.dateDistance = formatDistance(
        new Date(this.createdAt),
        new Date(),
        { addSuffix: true }
      )

      const isAnonymous = this.isAnonymous && this.sender.profile != this.profile 

      this.name = isAnonymous
        ? 'Anonymous'
        : this.sender.name

      this.bio = isAnonymous
        ? 'Mysterious'
        : this.sender.bio

      document.getElementById('description').innerText = this.description
      this.loading = false
    
    } catch (err) {


    }
  },

  data() {
    return {
      mdiChevronDoubleRight,
      mdiAccountCircle,
      mdiShare,
      mdiBookmarkOutline,
      mdiChevronUp,
      mdiChevronDown,

      snackMsg: '',
      snackOn: false,
      loading: true,
      playerHeight: 0,
      theaterMode: false,
      expandDescription: false,
      dialogOn: false,
      newVideoList: false,
      items: [
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
      ],
      model: ['Carrots'],
      url: '',
      tags: [],
      title: '',
      sender: {},
      author: '',
      description: '',
      createdAt: '',
      name: '',
      bio: '',
      isYoutube: null,
      isAnonymous: null,
      isPublic: null,
      isUnlisted: null
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile/id',
      navTempOn: 'application/navTempOn',
      navTemp: 'application/navTemp',
      navOn: 'application/navOn',
      mini: 'application/mini'
    }),

    expandIcon() {
      return this.expandDescription
        ? 'mdiChevronUp'
        : 'mdiChevronDown'
    },

    //notice that !navOn => theater mode is on
    paddingBar() {
      return !this.navOn
        ? 'padding-bar'
        : ''
    },

    playerClass() {
      return this.theaterMode
        ? 'py-0'
        : 'my-3'
    },

    numCols() {
      return this.theaterMode ? 12 : 8
    },

    skeletonHeight() {
      return this.mini ? 68 : 60
    }
  },

  methods: {
    ...mapMutations({
      toggleMini: 'application/mini',
      setNavOn: 'application/navOn',
      toggleNavTemp: 'application/navTemp',

      SET_LIBRARY_TYPE: 'note/SET_LIBRARY_TYPE',
      SET_LIBRARY_ID: 'note/SET_LIBRARY_ID'
    }),
    snackUpdated() {
      this.snackMsg = 'videoUpdated'
      this.snackOn = true
    },
    setPlayerHeight({ theaterModeOn, height }) {
      this.playerHeight = height + (!theaterModeOn ? 10 : 0)
    },
    theaterModeToggle() {
      this.theaterMode = !this.theaterMode
    }
  }
}
</script>

<style scoped>
.padding-bar {
  padding-right: 10px;
}
</style>